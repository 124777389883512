const Email = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.5028 16.667C17.963 16.667 18.3337 16.2939 18.3337 15.8337V5.00033C18.3337 4.54009 17.9606 4.16699 17.5003 4.16699H10.0003H2.50033C2.04009 4.16699 1.66699 4.54009 1.66699 5.00033V10.417V15.8337C1.66699 16.2939 2.03686 16.667 2.4971 16.667C5.20161 16.667 13.7977 16.667 17.5028 16.667Z"
        stroke="#999999"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.66699 5.8335V5.8335C6.0038 11.0377 13.9969 11.0377 18.3337 5.8335V5.8335"
        stroke="#999999"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default Email
